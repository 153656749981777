import {
  deleteUserErrors,
  useDeleteUserMutation,
} from '@energy-stacks/obelis/feature-users-data';
import { useESSnackbar } from '@energy-stacks/core/ui';
import { ConfirmDeleteDialog } from '@energy-stacks/shared';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

export const DeleteUser = () => {
  const params = useParams<{ userId: string }>();
  const [t] = useTranslation('users');
  const { showSnackbar } = useESSnackbar();
  const navigate = useNavigate();
  const [deleteUser] = useDeleteUserMutation();

  const handleCloseDialog = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleDeleteUser = useCallback(() => {
    if (!params.userId) {
      return;
    }
    return deleteUser(params.userId)
      .unwrap()
      .then(() => {
        showSnackbar('success', 'deleteUserSuccess', 'users');
        handleCloseDialog();
      })
      .catch((error) => {
        showSnackbar('error', deleteUserErrors[error.data?.errorCode], 'users');
      });
  }, [deleteUser, handleCloseDialog, params.userId, showSnackbar]);

  return (
    <ConfirmDeleteDialog
      title={t('deleteUserConfirmTitle')}
      onCancel={handleCloseDialog}
      onConfirm={handleDeleteUser}
    />
  );
};
