import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';

export interface ESMenuItemProps {
  icon?: React.ReactNode;
  children: React.ReactNode;
  disabled?: boolean;
  onClick: () => void;
  testId?: string;
}

export const ESMenuItem: React.FC<ESMenuItemProps> = ({
  onClick,
  icon,
  children,
  disabled = false,
  testId,
}) => {
  return (
    <MenuItem
      disabled={disabled}
      data-testid={testId}
      onClick={(event) => {
        event.stopPropagation();
        onClick();
      }}
    >
      {icon ? (
        <ListItemIcon
          sx={{
            mr: 5,
          }}
        >
          {icon}
        </ListItemIcon>
      ) : null}
      <ListItemText primary={children} />
    </MenuItem>
  );
};
