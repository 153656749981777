import React from 'react';
import { useObelisFeatureGuard } from './useObelisFeatureGuard';
import { ObelisFeature } from './obelisFeature';

export const ObelisFeatureRoleGuard: React.FC<{
  children: React.ReactNode;
  feature: ObelisFeature;
}> = ({ children, feature }) => {
  const hasAccess = useObelisFeatureGuard(feature);

  if (!hasAccess) {
    return null;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
