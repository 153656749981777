import { OidcUserStatus, useOidc, useOidcUser } from '@axa-fr/react-oidc';
import { useEffect } from 'react';
import {
  CommonUser,
  loginError,
  loginStart,
  loginSuccess,
  setHasRequestedAccess,
  useAppDispatch,
} from '@energy-stacks/store';

export const useLogin = <R, T extends CommonUser>(
  userNormalizer: (dto: R) => T
) => {
  const userInfo = useOidcUser();
  const userDto = userInfo.oidcUser as R;
  const user = userDto ? userNormalizer(userDto) : null;
  const loadingState = userInfo.oidcUserLoadingState;
  const dispatch = useAppDispatch();
  const { isAuthenticated, login } = useOidc();

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout> | undefined;
    if (!isAuthenticated) {
      timeoutId = setTimeout(() => {
        login();
      }, 1000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [login, isAuthenticated]);

  useEffect(() => {
    if (loadingState === OidcUserStatus.Loading) {
      dispatch(loginStart());
    }
  }, [loadingState, dispatch]);

  useEffect(() => {
    if (loadingState === OidcUserStatus.LoadingError) {
      dispatch(loginError());
    }
  }, [loadingState, dispatch]);

  useEffect(() => {
    if (loadingState === OidcUserStatus.Loaded) {
      dispatch(loginSuccess(user));

      if (user && !user.userRole) {
        dispatch(setHasRequestedAccess(false));
      }
    }
  }, [user, loadingState, dispatch]);
};
