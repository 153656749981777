import { useTranslation } from 'react-i18next';
import { Chip } from '@mui/material';
import { JobStatus } from './types/jobStatuses';

export interface JobStatusChipProps {
  status: JobStatus | undefined;
  isActionable?: boolean;
  disabled?: boolean;
}

interface JobStatusInfo {
  bgColor: string;
  color: string;
  hoverBgColor?: string;
  hoverColor?: string;
}

const jobStatusToChipInfoMap: Record<JobStatus, JobStatusInfo> = {
  DONE: {
    bgColor: 'success.light',
    color: 'success.dark',
    hoverBgColor: 'success.dark',
    hoverColor: 'success.light',
  },
  ENROUTE: {
    bgColor: 'primary.light',
    color: 'primary.dark',
    hoverBgColor: 'primary.800',
    hoverColor: 'primary.light',
  },
  FAILED: {
    bgColor: 'orange.light',
    color: 'orange.dark',
  },
  CANCELLED: {
    bgColor: 'orange.light',
    color: 'orange.dark',
  },
  PLANNED: {
    bgColor: 'secondary.light',
    color: 'secondary.main',
    hoverBgColor: 'secondary.main',
    hoverColor: 'secondary.light',
  },
  TO_DO: {
    bgColor: 'cyan.50',
    color: 'cyan.500',
  },
  PENDING: {
    bgColor: 'gray.200',
    color: 'black',
  },
};

export const JobStatusChip: React.FC<JobStatusChipProps> = ({
  status,
  isActionable,
}) => {
  const [t] = useTranslation('fleetShared');

  if (!status) {
    return null;
  }
  const { bgColor, color, hoverBgColor, hoverColor } =
    jobStatusToChipInfoMap[status];

  return (
    <Chip
      size="medium"
      label={t(`jobStatuses.${status}`)}
      sx={{
        backgroundColor: bgColor,
        color,
        fontWeight: 500,
        cursor: 'inherit',
        '&:hover':
          isActionable && hoverBgColor && hoverColor
            ? {
                backgroundColor: hoverBgColor,
                color: hoverColor,
              }
            : undefined,
      }}
    />
  );
};
