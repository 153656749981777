import { UserRoleEnum } from '@energy-stacks/obelis/feature-users-data';
import { ESButton, usePopover } from '@energy-stacks/core/ui';
import { ExpandLessRounded, ExpandMoreRounded } from '@mui/icons-material';
import { Menu, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useUserRoleSelectOptions } from '@energy-stacks/shared';

interface ObelisUserRoleSelectButtonProps {
  role?: UserRoleEnum;
  onRoleChanged: (role: UserRoleEnum) => void;
}

export const ObelisUserRoleSelectButton: React.FC<
  ObelisUserRoleSelectButtonProps
> = ({ role, onRoleChanged }) => {
  const { t } = useTranslation('users');

  const { anchorEl, closePopover, openPopover, popoverOpen } = usePopover();

  const options = useUserRoleSelectOptions(UserRoleEnum);

  const icon = popoverOpen ? (
    <ExpandLessRounded fontSize="small" />
  ) : (
    <ExpandMoreRounded fontSize="small" />
  );

  return (
    <>
      <ESButton
        size="small"
        variant="text"
        sx={{
          textTransform: 'none',
          color: (theme) => theme.palette.text.secondary,
        }}
        endIcon={icon}
        onClick={openPopover}
      >
        {role ? t(role) : t('role')}
      </ESButton>

      <Menu anchorEl={anchorEl} open={popoverOpen} onClose={closePopover}>
        {options.map((option) => (
          <MenuItem
            key={option.value}
            onClick={() => {
              onRoleChanged(option.value as UserRoleEnum);
              closePopover();
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
