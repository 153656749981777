import { useOidcUser } from '@axa-fr/react-oidc';
import { ObelisUserDto } from './obelisUserDto';
import { obelisUserModelNormalizer } from './normalizers/obelisUserModelNormalizer';

export const useObelisUserInfo = () => {
  const userInfo = useOidcUser();
  const userDto = userInfo.oidcUser as unknown as ObelisUserDto | null;
  const user = userDto ? obelisUserModelNormalizer(userDto) : null;

  return {
    user,
    loadingState: userInfo.oidcUserLoadingState,
  };
};
