import { SidebarGroup } from '@energy-stacks/core/sidebar';
import { ESManageAccountsIcon } from '@energy-stacks/shared';
import { useTranslation } from 'react-i18next';
import { useGetUserRequestsQuery } from '@energy-stacks/obelis/feature-users-data';
import {
  ObelisFeatureRoleGuard,
  useObelisFeatureGuard,
} from '@energy-stacks/obelis/feature-user-access';
import { ObelisRoutes } from '@energy-stacks/obelis/shared';

export const UsersDrawerItem = () => {
  const { t } = useTranslation('users');
  const itemLabel = t('title');
  const canFetchAccessRequests = useObelisFeatureGuard(
    'accessRequestManagement'
  );

  return (
    <ObelisFeatureRoleGuard feature="userManagement">
      <SidebarGroup
        testId="userManagementSidebarButton"
        groupInitialPath={ObelisRoutes.Users}
        groupIcon={
          canFetchAccessRequests ? (
            <ManageAccountsNotificationIcon />
          ) : (
            <ESManageAccountsIcon />
          )
        }
        groupLabel={itemLabel}
      ></SidebarGroup>
    </ObelisFeatureRoleGuard>
  );
};

const ManageAccountsNotificationIcon = () => {
  const { data: userRequests, isSuccess } = useGetUserRequestsQuery();

  return (
    <ESManageAccountsIcon
      showBadge={isSuccess && userRequests && userRequests.length > 0}
    />
  );
};
