export const ObelisRoutes = {
  ChargingHubs: '/charging-hubs',
  AddChargingHub: 'add',
  ChargingHubDetailsGeneralTab: 'general',
  ChargingHubDetailsChargingStationsTab: 'charging-stations',
  ChargingHubDetailsReportsTab: 'reports',
  ChargingHubDetails: ':id',
  EditChargingHub: ':id/edit',
  ChargingSessions: '/charging-sessions',
  ChargingStations: '/charging-stations',
  SystemReports: '/system-reports',
  ChargingStationDetails: ':id',
  ChargingStationDetailsGeneralTab: 'general',
  CreateReport: 'create',
  EditReport: ':uid/edit',
  ChargingStationDetailsSessionsTab: 'sessions',
  AddChargingStation: 'add',
  EditChargingStationGeneralDetails: ':id/edit',
  ChargingStationDetailsReportsTab: 'reports',
  Users: '/users',
  UserAccessRequests: 'requests',
  EditUser: ':userId/edit',
  DeleteUser: ':userId/delete',
  AccessDenied: '/access-denied',
};
