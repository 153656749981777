import {
  RequestAccessApiErrorKey,
  requestAccessApiErrors,
  useObelisUserInfo,
  useRequestAccessMutation,
} from '@energy-stacks/obelis/feature-users-data';
import { useESSnackbar } from '@energy-stacks/core/ui';
import { setHasRequestedAccess, useAppDispatch } from '@energy-stacks/store';
import { useCallback } from 'react';
import { AccessDeniedPage } from '@energy-stacks/user-access';
import { ObelisLogoOverlay } from '@energy-stacks/obelis/shared';

export const ObelisAccessDeniedPage = () => {
  const { user } = useObelisUserInfo();

  const dispatch = useAppDispatch();

  const [requestAccess, { isLoading }] = useRequestAccessMutation();
  const { showSnackbar } = useESSnackbar();

  const handleRequestAccess = useCallback(() => {
    requestAccess()
      .unwrap()
      .then(() => {
        showSnackbar('success', 'requestAccessSuccess', 'shared');
        dispatch(setHasRequestedAccess(true));
      })
      .catch((error) => {
        showSnackbar(
          'error',
          requestAccessApiErrors[error.data?.errorCode],
          'shared'
        );

        const alreadyRequestedAccess =
          error.data?.errorCode === RequestAccessApiErrorKey.ALREADY_EXISTS;

        if (alreadyRequestedAccess) {
          dispatch(setHasRequestedAccess(true));
        }
      });
  }, [requestAccess, showSnackbar, dispatch]);

  return (
    <AccessDeniedPage
      userActive={user?.isActive ?? false}
      userHasRoleAssigned={true}
      shouldRequestAccess={handleRequestAccess}
      requestAccessIsLoading={isLoading}
      LoadingComponent={() => <ObelisLogoOverlay />}
    />
  );
};
