import { userRoleNormalizer } from './userRoleNormalizer';
import { ExtendedObelisUserDto } from '../extendedObelisUserDto';
import { ExtendedObelisUserModel } from '../extendedUserModel';

export const extendedObelisUserModelNormalizer = (
  user: ExtendedObelisUserDto
): ExtendedObelisUserModel => ({
  userId: user.userId,
  displayName: user.displayName,
  emailAddress: user.emailAddress,
  isActive: user.isActive,
  userRole: userRoleNormalizer(user.userRole),
});
