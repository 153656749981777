import { Avatar, Stack, useTheme } from '@mui/material';
import { getUserInitials } from '@energy-stacks/shared';

export const UserCell: React.FC<{
  username: string;
  userId: string;
  userEmail: string;
}> = ({ username }) => {
  const userInitials = getUserInitials(username);
  const theme = useTheme();

  return (
    <Stack direction={'row'} alignItems={'center'}>
      <Avatar
        alt={userInitials}
        src={userInitials}
        sx={{
          ...theme.typography.body1,
          marginRight: 2,
        }}
        color="inherit"
      >
        {userInitials}
      </Avatar>
      {username}
    </Stack>
  );
};
