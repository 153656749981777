import { useTranslation } from 'react-i18next';

export const useUserRoleSelectOptions = (roleEnum: object) => {
  const [t] = useTranslation('users');
  const userRoleArray = Object.values(roleEnum).filter(
    (v) => typeof v === 'string'
  );

  const options = userRoleArray.map((role) => ({
    value: role,
    label: t(role),
  }));

  return options;
};
