import { useAppSelector } from '@energy-stacks/obelis/store';

/**
 * User Models returned from 'users/me' endpoints for both Broker and CSMS do not return the user id.
 * We will use the email address as the unique identifier for the user to determine if the currently
 * logged in user corresponds to the provided email address.
 */
export const useIsLoggedInUser = (emailAddress: string) => {
  const user = useAppSelector((state) => state.user.user);
  return (user && user.emailAddress === emailAddress) || false;
};
