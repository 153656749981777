import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Column } from '@tanstack/react-table';
import {
  MenuItem,
  Checkbox,
  ListItemText,
  Divider,
  Box,
  Stack,
} from '@mui/material';
import { intersection, xor } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { SearchTextField } from './SearchTextField';
import { TableFilterButton } from './TableFilterButton';
import { ESTooltip } from './ESTooltip';

export type FilterOption = {
  value: string | string[];
  label: string;
  Icon?: React.ReactNode;
};

export interface TableColumnFilterProps {
  columnLabel: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  column: Column<any, unknown> | undefined;
  options: FilterOption[];
  isSearchable?: boolean;
  isClearable?: boolean;
  hasTooltip?: boolean;
  testId?: string;
  onApplyFilter?: (option: string[]) => void;
  shouldScroll?: boolean;
}

export const TableColumnFilter: React.FC<TableColumnFilterProps> = ({
  columnLabel,
  column,
  options,
  isSearchable = false,
  isClearable = false,
  hasTooltip = false,
  testId,
  onApplyFilter,
  shouldScroll = false,
}) => {
  const [t] = useTranslation('shared');
  const [inputValue, setInputValue] = useState<string>('');
  const applyFiltersRef = useRef(onApplyFilter);
  const [filtersState, setFiltersState] = useState<string[]>(
    (column?.getFilterValue() ?? []) as string[]
  );

  //NOTE: we are not using debounce at this moment. We are waiting real product to se how many lists items will be in real
  const searchedOptions = isSearchable // If filter options are not searchable do not filter them on every input change
    ? options.filter((option) => {
        return option.label.toLowerCase().includes(inputValue.toLowerCase());
      })
    : options;

  const handleApplyFilter = useCallback(
    (option: FilterOption) => {
      if (!column) {
        return;
      }
      const newValue = xor(
        filtersState,
        typeof option.value === 'string' ? [option.value] : option.value
      ).flat();

      setFiltersState(newValue);
      applyFiltersRef.current?.(newValue);
      column?.setFilterValue(newValue);
    },
    [column, filtersState]
  );

  const checkedFilters = column
    ? (column.getFilterValue() as string[]) || []
    : [];

  const appliedFiltersCount = useMemo(() => {
    const firstOptionValue = options[0]?.value;
    if (typeof firstOptionValue === 'string') {
      return checkedFilters.length;
    }

    return options.filter((option) => {
      return (
        intersection(option.value, (column?.getFilterValue() ?? []) as string[])
          .length > 0
      );
    }).length;
  }, [checkedFilters.length, column, options]);

  return (
    <TableFilterButton
      testId={testId}
      title={columnLabel}
      appliedFiltersCount={appliedFiltersCount}
      renderPopover={() => {
        return (
          <Box sx={{ minWidth: 160 }}>
            {isSearchable ? (
              <Box
                sx={{
                  position: 'sticky',
                  p: 3,
                  top: 0,
                  backgroundColor: ({ palette }) => palette.background.paper,
                  zIndex: 2,
                  '& .MuiInput-root': {
                    paddingY: 1,
                  },
                }}
              >
                <SearchTextField
                  value={inputValue}
                  autoFocus
                  onChange={setInputValue}
                  testId={testId}
                />
              </Box>
            ) : null}
            {searchedOptions.length < 1 ? (
              <Box p={3}>
                <ListItemText primary={t('noSearchOptions')} />
              </Box>
            ) : (
              <Box
                style={
                  shouldScroll
                    ? { maxHeight: 250, overflow: 'auto' }
                    : undefined
                }
              >
                {searchedOptions.map((option, index) => {
                  return (
                    <MenuItem
                      data-testid={`${testId}MenuItem${index}`}
                      key={
                        Array.isArray(option.value)
                          ? option.value[0]
                          : option.value
                      }
                      value={option.value}
                      onClick={() => {
                        handleApplyFilter(option);
                      }}
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        gap={2.5}
                        marginRight={2.5}
                      >
                        <Checkbox
                          sx={{ p: 0 }}
                          checked={
                            intersection(
                              checkedFilters,
                              Array.isArray(option.value)
                                ? option.value
                                : [option.value]
                            ).length > 0
                          }
                        />
                        {option.Icon ? option.Icon : null}
                      </Stack>

                      <ESTooltip title={hasTooltip ? option.label : ''}>
                        <ListItemText
                          primary={option.label}
                          sx={{
                            '& > span': {
                              maxWidth: 500,
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                            },
                          }}
                          onClick={(event) => {
                            event.stopPropagation();
                            handleApplyFilter(option);
                          }}
                        />
                      </ESTooltip>
                    </MenuItem>
                  );
                })}
              </Box>
            )}
            {isClearable ? (
              <Box
                sx={{
                  position: 'sticky',
                  bottom: 0,
                  backgroundColor: ({ palette }) => palette.background.paper,
                  zIndex: 2,
                  '& .MuiMenuItem-root': {
                    paddingY: 3,
                  },
                }}
              >
                <Divider />
                <MenuItem
                  disabled={checkedFilters.length === 0}
                  onClick={() => {
                    column && column.setFilterValue([]);
                    applyFiltersRef.current?.([]);
                  }}
                >
                  <ListItemText
                    sx={{
                      '& .MuiListItemText-primary': { color: 'primary.main' },
                    }}
                    primary={t('clearAll')}
                  />
                </MenuItem>
              </Box>
            ) : null}
          </Box>
        );
      }}
      isActive={checkedFilters.length > 0}
    />
  );
};
