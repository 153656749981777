import React from 'react';
import { ESButton } from '@energy-stacks/core/ui';
import { useTranslation } from 'react-i18next';

interface DenyButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const DenyButton: React.FC<DenyButtonProps> = ({ onClick }) => {
  const { t } = useTranslation('users');

  return (
    <ESButton variant="outlined" size="small" color="error" onClick={onClick}>
      {t('deny')}
    </ESButton>
  );
};
