import { DateRange } from '@energy-stacks/core/date-range-picker';
import {
  DEFAULT_PAGINATION_CONFIG,
  ESTable,
  ESTableBodyCell,
  ESTableBodyCompose,
  ESTableBodyRow,
  ESTableHead,
  ESTablePagination,
  ESTableWrapper,
  ESTextButton,
  useFitRows,
} from '@energy-stacks/core/ui';
import {
  ChargingSessionModel,
  ChargingSessionsModel,
  ReasonModel,
  TariffTypeModel,
} from '@energy-stacks/obelis/feature-charging-sessions-data';
import {
  ESTooltip,
  ExpandButton,
  formatDateTime,
  NoTableData,
  TableDateTimeFilter,
  TableSearchField,
} from '@energy-stacks/shared';
import { Stack } from '@mui/material';
import {
  PaginationState,
  Row,
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React from 'react';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDurationInSeconds } from '@energy-stacks/fleet/shared';
import { ChargingSessionsTableReasonFilter } from './ChargingSessionsTableReasonFilter';
import { ChargingSessionsTableTariffTypeFilter } from './ChargingSessionsTableTariffTypeFilter';
import { ChargingSessionsTableRow } from './ChargingSessionsTableRow';

const ROW_HEIGHT = 60;
const MAX_CHARACTERS_LENGTH = 36;

interface ChargingSessionsTableProps {
  chargingSessionsResponse: ChargingSessionsModel | undefined;
  onDateRangeChange: (dateRange: DateRange | undefined) => void;
  dateRange: DateRange | undefined;
  pagination: PaginationState;
  onPaginationChange: Dispatch<SetStateAction<PaginationState>>;
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
  sorting: SortingState;
  onSortingChange: Dispatch<SetStateAction<SortingState>>;
  testId?: string;
  reasonFilter: ReasonModel | undefined;
  tariffTypeFilter: TariffTypeModel | undefined;
  onSetReasonFilter: (reason: ReasonModel | undefined) => void;
  onSetTariffTypeFilter: (tariffType: TariffTypeModel | undefined) => void;
}

export const ChargingSessionsTable: React.FC<ChargingSessionsTableProps> = ({
  chargingSessionsResponse,
  onDateRangeChange,
  dateRange,
  pagination,
  onPaginationChange,
  search,
  setSearch,
  sorting,
  onSortingChange,
  testId,
  reasonFilter,
  tariffTypeFilter,
  onSetReasonFilter,
  onSetTariffTypeFilter,
}) => {
  const { t } = useTranslation('chargingSessions');

  const columnHelper = createColumnHelper<ChargingSessionModel>();

  const columns = [
    {
      id: 'expand',
      size: 20,
      cell: ({ row }: { row: Row<ChargingSessionModel> }) => {
        return row.original.chargingPeriods ? (
          <ExpandButton
            onToggleExpand={() => row.toggleExpanded()}
            expanded={row.getIsExpanded()}
          />
        ) : null;
      },
    },
    columnHelper.accessor('txnId', {
      header: () => t('txnId'),
      footer: (props) => props.column.id,
      cell: (info) => info.getValue(),
      enableSorting: false,
    }),
    columnHelper.accessor('sessionStart', {
      header: () => t('sessionStart'),
      footer: (props) => props.column.id,
      cell: (info) => (info.getValue() ? formatDateTime(info.getValue()) : '-'),
      enableSorting: false,
    }),
    columnHelper.accessor('sessionStop', {
      header: () => t('sessionStop'),
      footer: (props) => props.column.id,
      cell: (info) => (info.getValue() ? formatDateTime(info.getValue()) : '-'),
      enableSorting: false,
    }),
    columnHelper.accessor('totalTime', {
      header: () => t('totalTime'),
      footer: (props) => props.column.id,
      cell: (info) => {
        return formatDurationInSeconds(info.getValue());
      },
      enableSorting: false,
    }),
    columnHelper.accessor('totalEnergy', {
      header: () => t('totalEnergy'),
      footer: (props) => props.column.id,
      cell: (info) => info.getValue(),
      enableSorting: false,
    }),
    columnHelper.accessor('emaId', {
      header: () => t('emaId'),
      footer: (props) => props.column.id,
      cell: (info) => {
        return info.getValue() ? info.getValue() : '-';
      },
      enableSorting: false,
    }),
    columnHelper.accessor('totalCost', {
      header: () => t('totalCost'),
      footer: (props) => props.column.id,
      cell: (info) => info.getValue(),
      enableSorting: false,
    }),
    columnHelper.accessor('totalParkingTime', {
      header: () => t('totalParkingTime'),
      footer: (props) => props.column.id,
      cell: (info) => info.getValue(),
      enableSorting: false,
    }),
    columnHelper.accessor('evseId', {
      header: () => t('evseId'),
      footer: (props) => props.column.id,
      cell: (info) => info.getValue(),
      enableSorting: false,
    }),
    columnHelper.accessor('reason', {
      header: () => t('reason'),
      footer: (props) => props.column.id,
      cell: (info) => {
        const reason = info.getValue();
        return reason ? t(reason) : '-';
      },
      enableSorting: false,
    }),
    columnHelper.accessor('tariffType', {
      header: () => t('tariffType'),
      footer: (props) => props.column.id,
      cell: (info) => t(info.getValue()),
      enableSorting: false,
    }),
  ];

  const instance = useReactTable({
    data: chargingSessionsResponse?.chargingSessions ?? [],
    columns,
    state: {
      pagination,
      sorting,
    },
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange,
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange,
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    pageCount: chargingSessionsResponse?.totalPages,
    manualSorting: true,
    enableColumnResizing: false,
  });

  const { rowsPerPageOptions } = useFitRows(
    instance,
    chargingSessionsResponse?.totalElements ?? 0
  );
  const filteredRows = instance.getFilteredRowModel().rows;
  const rows = instance.getRowModel().rows;
  const hasRows = rows.length !== 0;

  const handleDateRangeFilterChange = (dateRange: DateRange) => {
    onDateRangeChange(dateRange);
    instance.setPageIndex(DEFAULT_PAGINATION_CONFIG.page);
  };

  const handleDateRangeFilterCleared = () => {
    onDateRangeChange(undefined);
    instance.setPageIndex(DEFAULT_PAGINATION_CONFIG.page);
  };

  const hasFilters =
    Boolean(dateRange) ||
    reasonFilter !== undefined ||
    tariffTypeFilter !== undefined;

  const clearAllFilters = () => {
    onDateRangeChange(undefined);
    onSetTariffTypeFilter(undefined);
    onSetReasonFilter(undefined);
    instance.setPageIndex(DEFAULT_PAGINATION_CONFIG.page);
  };

  const onSearchChange = useCallback(
    (value: string) => {
      const trimmedNewValue = value.trim();
      setSearch(trimmedNewValue);
    },
    [setSearch]
  );

  return (
    <>
      <Stack direction="row" gap={3} mb={6} alignItems="center">
        <ESTooltip title={t('sessionsSearchPlaceholder')}>
          <TableSearchField
            testId={testId}
            value={search}
            onChange={(val) => {
              onSearchChange(val);
            }}
            tableInstance={instance}
            debounce={500}
            maxNumberOfCharacters={MAX_CHARACTERS_LENGTH}
            placeholder={t('sessionsSearchPlaceholder')}
          />
        </ESTooltip>
        <TableDateTimeFilter
          title={t('timeRange')}
          isActive={dateRange !== undefined}
          defaultDateRange={dateRange}
          onDateRangeApplied={handleDateRangeFilterChange}
          onDateRangeCleared={handleDateRangeFilterCleared}
        />
        <ChargingSessionsTableReasonFilter
          testId={`${testId}reason`}
          reasonFilter={reasonFilter}
          onSetReasonFilter={onSetReasonFilter}
        />
        <ChargingSessionsTableTariffTypeFilter
          testId={`${testId}tariffType`}
          tariffTypeFilter={tariffTypeFilter}
          onSetTariffTypeFilter={onSetTariffTypeFilter}
        />
        {hasFilters ? (
          <ESTextButton
            testId={`${testId}ClearAllButton`}
            onClick={clearAllFilters}
          >
            {t('clearAll')}
          </ESTextButton>
        ) : null}
      </Stack>
      <ESTableWrapper>
        <ESTable>
          <ESTableHead instance={instance} />
          <ESTableBodyCompose>
            {rows.map((row) => {
              return (
                <React.Fragment key={row.id}>
                  <ESTableBodyRow
                    sx={{
                      '&.MuiTableRow-root': {
                        height: ROW_HEIGHT,
                      },
                    }}
                    onRowClick={() => {
                      row.toggleExpanded();
                    }}
                  >
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <ESTableBodyCell
                          sx={{
                            '&': {
                              borderColor: row.getIsExpanded()
                                ? 'transparent'
                                : undefined,
                              width: cell.column.getSize(),
                              whiteSpace: 'nowrap',
                            },
                          }}
                          key={cell.id}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </ESTableBodyCell>
                      );
                    })}
                  </ESTableBodyRow>
                  {row.original.chargingPeriods ? (
                    <ChargingSessionsTableRow
                      expanded={row.getIsExpanded()}
                      chargingPeriods={row.original.chargingPeriods}
                      colSpan={instance.getAllColumns().length}
                    />
                  ) : null}
                </React.Fragment>
              );
            })}
          </ESTableBodyCompose>
        </ESTable>

        {!hasRows ? (
          <NoTableData message={t('thereAreNoChargingSessions')} />
        ) : null}

        <ESTablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          instance={instance}
          count={chargingSessionsResponse?.totalElements || filteredRows.length}
        />
      </ESTableWrapper>
    </>
  );
};
