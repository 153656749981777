import {
  ESDialogActionButton,
  ESDialogActions,
  ESDialogContent,
  ESDialogTitle,
} from '@energy-stacks/core/ui';
import { Dialog } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGetUserRequestsQuery } from '@energy-stacks/obelis/feature-users-data';
import {
  useConfirmCloseDialog,
  RefetchOnError,
  ESLoadingIndicator,
  ConfirmCloseLightDialog,
} from '@energy-stacks/shared';
import { ObelisUserManageAccessDialogForm } from './ObelisUserManageAccessDialogForm';
import { ObelisRoutes } from '@energy-stacks/obelis/shared';

export const ObelisUserManageAccessDialog: React.FC = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  useEffect(() => {
    setIsDialogOpen(true);
  }, []);

  const { t } = useTranslation('users');
  const navigate = useNavigate();

  const {
    data: userAccessRequests,
    isLoading,
    isError,
    isSuccess,
    refetch,
  } = useGetUserRequestsQuery();

  const [isFormDirty, setIsFormDirty] = useState(false);

  const {
    isConfirmCloseDialogOpen,
    handleOpenConfirmDialog,
    handleLeaveWithoutSaving,
    handleStay,
  } = useConfirmCloseDialog(isFormDirty);

  useEffect(() => {
    if (isSuccess && userAccessRequests.length === 0) {
      navigate(ObelisRoutes.Users, { replace: true });
    }
  }, [isSuccess, userAccessRequests, navigate]);

  if (isError) return <RefetchOnError onRefetch={refetch} />;

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isDialogOpen}
        onClose={handleOpenConfirmDialog}
      >
        <ESDialogTitle>{t('manageAccess')}</ESDialogTitle>

        {isLoading || !userAccessRequests ? (
          <>
            <ESDialogContent>
              <ESLoadingIndicator />
            </ESDialogContent>
            <ESDialogActions>
              <ESDialogActionButton
                color="error"
                onClick={handleOpenConfirmDialog}
              >
                {t('cancel')}
              </ESDialogActionButton>
              <ESDialogActionButton variant="contained" disabled>
                {t('done')}
              </ESDialogActionButton>
            </ESDialogActions>
          </>
        ) : (
          <ObelisUserManageAccessDialogForm
            onClose={handleOpenConfirmDialog}
            closeWithoutConfirm={handleLeaveWithoutSaving}
            userAccessRequestModels={userAccessRequests}
            setIsFormDirty={setIsFormDirty}
          />
        )}
      </Dialog>

      <ConfirmCloseLightDialog
        open={isConfirmCloseDialogOpen}
        onStay={handleStay}
        onLeaveWithoutSaving={handleLeaveWithoutSaving}
      />
    </>
  );
};
