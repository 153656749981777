import {
  ESDialogActionButton,
  ESDialogActions,
  ESDialogContent,
  ESDialogTitle,
  useESSnackbar,
} from '@energy-stacks/core/ui';
import { Dialog, FormControlLabel, Switch, Stack } from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { UserRoleSelect } from './UserRoleSelect';
import { useCallback, useEffect } from 'react';
import {
  useEditUserMutation,
  useGetUsersQuery,
  EditUserFormData,
  usersApiErrors,
} from '@energy-stacks/obelis/feature-users-data';
import { yupResolver } from '@hookform/resolvers/yup';
import { editUserValidationScheme } from './editUSerValidationScheme';
import { ObelisRoutes } from '@energy-stacks/obelis/shared';

const editUserDefaultValues: EditUserFormData = {
  userId: '',
  userRole: 'noRole',
  isActive: false,
};

export const EditUser = () => {
  const [t] = useTranslation('users');
  const [tShared] = useTranslation('shared');
  const navigate = useNavigate();
  const { showSnackbar } = useESSnackbar();

  const {
    control,
    handleSubmit,
    formState: { isValid, isDirty, errors },
    reset: resetForm,
    setValue,
    getValues,
  } = useForm<EditUserFormData>({
    defaultValues: editUserDefaultValues,
    mode: 'onChange',
    resolver: yupResolver(editUserValidationScheme),
  });

  const [editUser, { isLoading }] = useEditUserMutation();

  const { userId } = useParams<{ userId: string }>();
  const { user, isSuccess } = useGetUsersQuery(undefined, {
    selectFromResult: ({ data, isSuccess }) => ({
      user: data?.find((user) => user.userId === userId),
      isSuccess,
    }),
  });

  const closeDialog = useCallback(() => {
    navigate(ObelisRoutes.Users);
  }, [navigate]);

  // if user extracted from url doesn't exist, show error message and navigate to users table
  useEffect(() => {
    if (isSuccess && !user) {
      showSnackbar('error', t('userNotFound'));
      closeDialog();
    }
  }, [isSuccess, user, navigate, showSnackbar, t, closeDialog]);

  useEffect(() => {
    if (!user) {
      return;
    }

    resetForm({
      userRole: user.userRole,
      isActive: user.isActive,
      userId: user.userId,
    });
  }, [user, resetForm, getValues, setValue]);

  const onSubmit: SubmitHandler<EditUserFormData> = (data) => {
    editUser({
      userId: data.userId,
      userRole: data.userRole,
      isActive: data.isActive,
    })
      .unwrap()
      .then(() => {
        showSnackbar('success', 'editUserSuccess', 'users');
        closeDialog();
      })
      .catch((error) => {
        showSnackbar('error', usersApiErrors[error.data?.errorCode], 'users');
      });
  };

  return (
    <Dialog open onClose={closeDialog} fullWidth>
      <ESDialogTitle>
        {t('editUserTitle', { name: user?.displayName })}
      </ESDialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ESDialogContent>
          <Stack spacing={6}>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={onChange}
                      disabled={isLoading}
                    />
                  }
                  label={t('activateUserCheckboxLabel')}
                />
              )}
              name="isActive"
            />

            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <UserRoleSelect
                  value={value ?? ''}
                  onChange={onChange}
                  onBlur={onBlur}
                  disabled={isLoading}
                  error={Boolean(errors.userRole)}
                  helperText={Boolean(errors.userRole) && t('userRoleRequired')}
                />
              )}
              name="userRole"
            />
          </Stack>
        </ESDialogContent>

        <ESDialogActions>
          <ESDialogActionButton
            color="error"
            onClick={closeDialog}
            disabled={isLoading}
          >
            {tShared('cancel')}
          </ESDialogActionButton>
          <ESDialogActionButton
            type="submit"
            disabled={!isValid || !isDirty}
            loading={isLoading}
            variant="contained"
          >
            {t('saveDialogAction')}
          </ESDialogActionButton>
        </ESDialogActions>
      </form>
    </Dialog>
  );
};
