import { useGetUsersQuery } from '@energy-stacks/obelis/feature-users-data';
import { Box } from '@mui/material';
import {
  ESPage,
  ES_PAGE_PADDING,
  ESPageLoadingIndicator,
  useDocumentTitle,
  RefetchOnError,
} from '@energy-stacks/shared';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { ObelisUserAccessNotificationsBanner } from './ObelisUserAccessNotificationsBanner';
import { Users } from './Users';
import { ObelisFeatureRoleGuard } from '@energy-stacks/obelis/feature-user-access';

export const UsersPage = () => {
  const { t } = useTranslation('users');
  const { isLoading, isSuccess, isError, refetch } = useGetUsersQuery();
  useDocumentTitle(t('title'));

  return (
    <ESPage title={t('title')}>
      <ObelisFeatureRoleGuard feature="accessRequestManagement">
        <Box
          sx={{
            mt: -ES_PAGE_PADDING,
            mr: -ES_PAGE_PADDING,
            mb: ES_PAGE_PADDING,
            ml: -ES_PAGE_PADDING,
          }}
        >
          <ObelisUserAccessNotificationsBanner />
        </Box>
      </ObelisFeatureRoleGuard>
      {isLoading ? <ESPageLoadingIndicator /> : null}
      {isSuccess ? <Users /> : null}
      {isError ? <RefetchOnError onRefetch={refetch} /> : null}

      <Outlet />
    </ESPage>
  );
};
