import { Status } from '@energy-stacks/obelis/feature-charging-stations-data';

type StatusColors = {
  bgColor: string;
  color: string;
};

export const statusColorsMap: Record<Status, StatusColors> = {
  AVAILABLE: {
    bgColor: 'success.light',
    color: 'success.dark',
  },
  BLOCKED: {
    bgColor: 'orange.light',
    color: 'error.dark',
  },
  CHARGING: {
    bgColor: 'primary.light',
    color: 'primary.dark',
  },
  UNAVAILABLE: {
    bgColor: 'grey.300',
    color: 'grey.900',
  },
  OUTOFORDER: {
    bgColor: 'grey.300',
    color: 'grey.900',
  },
  PLANNED: {
    bgColor: 'primary.light',
    color: 'primary.dark',
  },
  REMOVED: {
    bgColor: 'cyan.50',
    color: 'cyan.500',
  },
  RESERVED: {
    bgColor: 'warning.light',
    color: 'warning.dark',
  },
  UNKNOWN: {
    bgColor: 'grey.300',
    color: 'grey.900',
  },
};
