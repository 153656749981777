import { Badge } from '@mui/material';
import { IconUsers } from '@tabler/icons-react';

import React from 'react';

interface ESManageAccountsIconProps {
  showBadge?: boolean;
}

export const ESManageAccountsIcon: React.FC<ESManageAccountsIconProps> = ({
  showBadge,
}) => {
  return (
    <Badge color="error" badgeContent="" variant="dot" invisible={!showBadge}>
      <IconUsers />
    </Badge>
  );
};
