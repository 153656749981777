import { appendZToDateString } from '@energy-stacks/shared';
import {
  ChargingPointDto,
  ChargingStationDetailsDto,
  EvseCapabilityDto,
} from '../chargingStationDetailsDto';
import {
  CapabilityModel,
  ChargingStationDetails,
  Evse,
} from '../chargingStationDetailsModel';
import {
  connectorFormatDtoToModelMap,
  connectorPowerTypeDtoToModelMap,
  connectorStandardDtoToTypeMap,
} from '@energy-stacks/data';

export const chargingStationDetailsNormalizer = (
  chargingStationDetailsDto: ChargingStationDetailsDto
): ChargingStationDetails => ({
  id: chargingStationDetailsDto.identityKey ?? '',
  name: chargingStationDetailsDto.name ?? '',
  chargingHub: {
    id: chargingStationDetailsDto.chargingHub?.chargingHubUid ?? '',
    name: chargingStationDetailsDto.chargingHub?.name ?? '',
  },
  coordinates: {
    latitude: chargingStationDetailsDto.coordinates?.latitude ?? '',
    longitude: chargingStationDetailsDto.coordinates?.longitude ?? '',
  },
  vendorModel: chargingStationDetailsDto.shadow?.model ?? '',
  vendor: chargingStationDetailsDto.shadow?.vendor ?? '',
  evses: (chargingStationDetailsDto.chargingPoints ?? []).map(
    chargingPointNormalizer
  ),
});

const chargingPointNormalizer = (chargingPointDto: ChargingPointDto): Evse => ({
  chargingPointId: chargingPointDto.uid ?? '',
  evseId: chargingPointDto.evseId ?? '',
  status: chargingPointDto.status,
  capabilities: (chargingPointDto.capabilities ?? []).map(
    (c) => mapCapabilitiesDtoToCapabilitiesModel[c]
  ),
  connectors:
    chargingPointDto.connectors?.map((c) => ({
      id: c.connectorUid ?? '',
      standard: c.standard && connectorStandardDtoToTypeMap[c.standard],
      powerType: c.powerType && connectorPowerTypeDtoToModelMap[c.powerType],
      format: c.format && connectorFormatDtoToModelMap[c.format],
      maxVoltage: c.maxVoltage,
      maxAmperage: c.maxAmperage,
      maxPower: c.maxPower,
      lastUpdated: appendZToDateString(chargingPointDto.statusLastUpdatedAt),
    })) ?? [],
});

const mapCapabilitiesDtoToCapabilitiesModel: Record<
  EvseCapabilityDto,
  CapabilityModel
> = {
  CHARGING_PROFILE_CAPABLE: 'chargingProfileCapable',
  CHARGING_PREFERENCES_CAPABLE: 'chargingPreferencesCapable',
  CHIP_CARD_SUPPORT: 'chipCardSupport',
  CONTACTLESS_CARD_SUPPORT: 'contactlessCardSupport',
  CREDIT_CARD_PAYABLE: 'creditCardPayable',
  DEBIT_CARD_PAYABLE: 'debitCardPayable',
  PED_TERMINAL: 'pedTerminal',
  REMOTE_START_STOP_CAPABLE: 'remoteStartStopCapable',
  RESERVABLE: 'reservable',
  RFID_READER: 'rfidReader',
  TOKEN_GROUP_CAPABLE: 'tokenGroupCapable',
  UNLOCK_CAPABLE: 'unlockCapable',
  START_SESSION_CONNECTOR_REQUIRED: 'startSessionConnectorRequired',
};
