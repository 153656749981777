import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ESBanner } from '@energy-stacks/core/ui';
import { Link, Typography } from '@mui/material';
import { useEffect } from 'react';

export const UserAccessRequestBanner: React.FC<{
  requestCount: number;
  navigateLink: string;
}> = ({ requestCount, navigateLink }) => {
  const [t] = useTranslation('users');
  const navigate = useNavigate();

  useEffect(() => {
    // We dispatch the resize event when banner changes so the Table can trigger useFitRow and resize
    window.dispatchEvent(new Event('resize'));
  }, [requestCount]);

  return (
    <ESBanner sx={{ display: 'flex', alignItems: 'center', px: 4, py: 3.5 }}>
      <>
        <Typography
          variant="body2"
          color={(theme) => theme.palette.common.white}
        >
          {t('usersAreRequestingAccess', { count: requestCount })}
        </Typography>
        <Link
          component="button"
          variant="body2"
          sx={{
            pl: 1,
            color: (theme) => theme.palette.primary.main,
            textDecoration: 'underline',
          }}
          onClick={() => navigate(navigateLink)}
        >
          {t('manageAccess')}
        </Link>
      </>
    </ESBanner>
  );
};
