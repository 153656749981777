import { numberValidityTest } from '@energy-stacks/shared';
import * as yup from 'yup';

export const connectorValidationScheme = yup.object().shape({
  connectorStandard: yup.object({
    label: yup.string(),
    value: yup.string().required('fieldIsRequired'),
  }),
  connectorFormat: yup.object({
    label: yup.string(),
    value: yup.string().required('fieldIsRequired'),
  }),
  powerType: yup.object({
    label: yup.string(),
    value: yup.string().required('fieldIsRequired'),
  }),
  maxVoltage: numberValidityTest
    .required('fieldIsRequired')
    .test('inRange', 'maxVoltageValueRange', (value) => {
      const num = Number(value);
      return num >= 200 && num <= 1000;
    }),
  maxAmperage: numberValidityTest
    .required('fieldIsRequired')
    .test('inRange', 'maxAmperageValueRange', (value) => {
      const num = Number(value);
      return num >= 5 && num <= 700;
    }),
  maxPower: numberValidityTest
    .required('fieldIsRequired')
    .test('inRange', 'maxPowerValueRange', (value) => {
      const num = Number(value);
      return num >= 0 && num <= 600;
    }),
});
