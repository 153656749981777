import { ESVirtualizedAutocomplete } from '@energy-stacks/core/ui';
import { useTranslation } from 'react-i18next';
import { UserRoleEnum } from '@energy-stacks/obelis/feature-users-data';
import React from 'react';

export const UserRoleSelect: React.FC<{
  value: string;
  disabled?: boolean;
  error?: boolean;
  helperText: React.ReactNode;
  onChange: (value: string) => void;
  onBlur: () => void;
}> = ({ value, disabled, error, helperText, onChange, onBlur }) => {
  const [t] = useTranslation('users');

  return (
    <ESVirtualizedAutocomplete
      value={{ value: value, label: t(value) }}
      label={t('userRole')}
      options={Object.entries(UserRoleEnum).map(([key, value]) => ({
        label: t(value),
        value: key,
      }))}
      onBlur={onBlur}
      onChange={(_, value) => {
        if (Array.isArray(value)) {
          return;
        }
        if (value) {
          onChange(value.value ?? '');
        }
      }}
      required
      error={error}
      helperText={helperText}
      disabled={disabled}
      noOptionsText={t('noUsersRole')}
    />
  );
};
