import { UserRole } from '../userRole';
import { UserRoleDto } from '../userRoleDto';

export const userRoleNormalizer = (userRole: UserRoleDto): UserRole => {
  return userRoleMap[userRole] ?? 'noRole';
};

const userRoleMap: Record<UserRoleDto, UserRole> = {
  OBELIS_ADMIN: 'admin',
  OBELIS_USER: 'user',
};

export const toUserRoleDto = (userRole: UserRole): UserRoleDto => {
  const map: Record<UserRole, UserRoleDto> = {
    admin: 'OBELIS_ADMIN',
    user: 'OBELIS_USER',
    noRole: null as unknown as UserRoleDto,
  } as const;

  return map[userRole];
};
