import {
  ESButton,
  ESDialogActions,
  ESDialogContent,
  ESDialogTitle,
} from '@energy-stacks/core/ui';
import { Dialog, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ConfirmCloseLightDialogProps {
  open: boolean;
  onStay: () => void;
  onLeaveWithoutSaving: () => void;
}

export const ConfirmCloseLightDialog: React.FC<
  ConfirmCloseLightDialogProps
> = ({ open, onStay, onLeaveWithoutSaving }) => {
  const { t } = useTranslation('shared');

  return (
    <Dialog open={open} fullWidth maxWidth={'sm'}>
      <ESDialogTitle>{t('discardChanges')}</ESDialogTitle>
      <ESDialogContent>
        <Typography variant="body1">{t('allChangesWillBeLost')}</Typography>
      </ESDialogContent>
      <ESDialogActions>
        <ESButton variant="text" onClick={onStay}>
          {t('continueEditing')}
        </ESButton>
        <ESButton onClick={onLeaveWithoutSaving}>{t('discard')}</ESButton>
      </ESDialogActions>
    </Dialog>
  );
};
