import { ObelisUserDto } from '../obelisUserDto';
import { ObelisUserModel } from '../obelisUserModel';
import { userRoleNormalizer } from './userRoleNormalizer';

export const obelisUserModelNormalizer = (
  obelisUserDto: ObelisUserDto
): ObelisUserModel => ({
  displayName: obelisUserDto.name,
  emailAddress: obelisUserDto.email,
  isActive: obelisUserDto.isActive,
  userRole: userRoleNormalizer(obelisUserDto.userRole),
});
