/**
 * Start with two uppercase letters
 * Asterisk (*)
 * Three alphanumeric characters
 * Asterisk (*)
 * The letter "E"
 * Anything after letter "E"
 *
 * e.g.
 * DE*EHT*EEWE4309*2
 * DE*EHT*EEWE4309*2D
 * DE*EHT*EEWE4309*2D1
 * DE*EHT*EEWE4309*2D*1
 * DE*EHT*EEWE4309*2D*12
 * DE*EHT*EEWE4309*2D*A
 * DE*EHT*EEWE4309*2D*1A
 */
export const OBELIS_EVSE_ID_REGEX =
  /^[A-Z]{2}\*[0-9a-zA-Z]{3}\*E(?:[0-9a-zA-Z])?/;
