import { CentralSystemStatus } from '@energy-stacks/broker/feature-system-preferences-data';
import { ConnectorModel } from './connectorModel';

export interface ChargingStationModel {
  identityKey: string;
  serialNumber: string;
  model: string;
  name: string;
  geolocation: GeoLocation;
  location: ChargingSite;
  firmware: string;
  csmsUuid: string;
  centralSystemStatus: CentralSystemStatus;
  ocppProtocol: string;
  dateCreated: string | null;
  connectors: ConnectorModel[];
  online: ChargingStationOnlineStatus;
  csStatus: ChargingStationStatus;
}

interface GeoLocation {
  latitude: string;
  longitude: string;
}

export interface ChargingSite {
  uuid: string;
  name: string;
}

export enum ChargingStationOnlineStatus {
  online = 'online',
  offline = 'offline',
  provisioned = 'provisioned',
}

export const chargingStationStatuses = [
  'online',
  'offline',
  'faulted',
  'provisioned',
] as const;
export type ChargingStationStatus = (typeof chargingStationStatuses)[number];
