import {
  UserRoleEnum,
  useGetUsersQuery,
} from '@energy-stacks/obelis/feature-users-data';
import {
  Avatar,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import { Control, UseFieldArrayUpdate } from 'react-hook-form';
import {
  ObelisUserAccessRequestFormEntry,
  UserAccessRequestFormData,
} from './ObelisUserManageAccessDialogForm';
import { ObelisUserRoleSelectButton } from './ObelisUserRoleSelectButton';
import {
  AcceptButton,
  DenyButton,
  getUserInitials,
} from '@energy-stacks/shared';
import { useTranslation } from 'react-i18next';

interface ObelisUserManageAccessItemProps {
  requestData: ObelisUserAccessRequestFormEntry;
  index: number;
  control: Control<UserAccessRequestFormData>;
  update: UseFieldArrayUpdate<UserAccessRequestFormData, 'userAccessRequests'>;
  isLast: boolean;
}

export const ObelisUserManageAccessItem: React.FC<
  ObelisUserManageAccessItemProps
> = ({ requestData, index, update, isLast }) => {
  const { t } = useTranslation('users');
  const { isAccepted, displayName, emailAddress, role } = requestData;
  const { user } = useGetUsersQuery(undefined, {
    selectFromResult: ({ data }) => ({
      user: data?.find((user) => user.emailAddress === emailAddress),
    }),
  });
  const userInitials = getUserInitials(displayName);
  const theme = useTheme();

  const handleAccept = (isAccepted: boolean) => {
    update(index, { ...requestData, isAccepted });
  };

  const handleRoleChange = (role: UserRoleEnum) => {
    update(index, { ...requestData, role });
  };

  const isDisabled = isAccepted === false;

  return (
    <>
      <ListItem sx={{ px: 0 }}>
        <ListItemAvatar
          sx={isDisabled ? { filter: 'grayscale(1)', opacity: 0.6 } : undefined}
        >
          <Avatar
            alt={userInitials}
            src={userInitials}
            color="inherit"
            sx={{
              ...theme.typography.body1,
            }}
          >
            {userInitials}
          </Avatar>
        </ListItemAvatar>

        <ListItemText
          primary={displayName}
          secondary={emailAddress}
          primaryTypographyProps={{
            color: isDisabled
              ? (theme) => theme.palette.text.disabled
              : undefined,
          }}
          secondaryTypographyProps={{
            color: isDisabled
              ? (theme) => theme.palette.text.disabled
              : undefined,
          }}
        />

        {isAccepted === null && (
          <Stack direction="row" alignItems={'center'} spacing={2.5}>
            <AcceptButton onClick={() => handleAccept(true)} />
            <DenyButton onClick={() => handleAccept(false)} />
          </Stack>
        )}

        {isAccepted === true && (
          <ObelisUserRoleSelectButton
            role={role}
            onRoleChanged={handleRoleChange}
          />
        )}

        {isAccepted === false && (
          <Typography
            variant="body2"
            color={(theme) => theme.palette.text.disabled}
          >
            {t('denied')}
          </Typography>
        )}
      </ListItem>
      {!isLast && <Divider />}
    </>
  );
};
