import React from 'react';
import { Navigate } from 'react-router-dom';
import { useObelisFeatureGuard } from './useObelisFeatureGuard';
import { ObelisFeature } from './obelisFeature';
import { ESRoutes } from '@energy-stacks/shared';

interface ObelisRoleProtectedRouteProps {
  children: React.ReactNode;
  feature: ObelisFeature;
}

export const ObelisRoleProtectedRoute: React.FC<
  ObelisRoleProtectedRouteProps
> = ({ children, feature }) => {
  const hasAccess = useObelisFeatureGuard(feature);

  if (!hasAccess) {
    return <Navigate to={ESRoutes.Root} replace />;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
