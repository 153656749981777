import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { ESTextField, ESTextFieldProps } from './ESTextField';
import {
  Box,
  FilterOptionsState,
  List,
  ListProps,
  Typography,
} from '@mui/material';
import {
  forwardRef,
  PropsWithChildren,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import { IconChevronDown } from '@tabler/icons-react';

type Option = { label: string; value: string };

type ESAutocompleteProps = {
  options: Option[];
  value: Option;
  isLoading?: boolean;
  label: string;
  required?: boolean;
  disabled?: boolean;
  error?: ESTextFieldProps['error'];
  helperText?: ESTextFieldProps['helperText'];
  onChange: (value: Option | undefined) => void;
  onBlur?: () => void;
  onScrollEndReached?: () => void;
  filterOptions?:
    | ((options: Option[], state: FilterOptionsState<Option>) => Option[])
    | undefined;
  onInputChange?: (value: string) => void;
  onClear?: () => void;
};

const CustomListBox = forwardRef<HTMLUListElement, PropsWithChildren>(
  function CustomListbox(props, ref) {
    const { children, ...other } = props;

    const innerRef = useRef<HTMLUListElement>(null);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    useImperativeHandle(ref, () => innerRef.current!);

    return (
      <List {...other} ref={innerRef}>
        {children}
      </List>
    );
  }
);

export const ESAutocomplete: React.FC<ESAutocompleteProps> = ({
  options,
  isLoading,
  value,
  label,
  required,
  disabled,
  onChange,
  onBlur,
  onScrollEndReached,
  filterOptions,
  onInputChange,
  onClear,
  error,
  helperText,
}) => {
  const listboxRef = useRef<HTMLUListElement>(null);
  const prevScrollTop = useRef(0);

  // Needed in order to keep the scroll position when new items are added to the bottom
  const ListboxComponent = useMemo(
    () => (props: ListProps) => <CustomListBox ref={listboxRef} {...props} />,
    []
  );

  return (
    <Autocomplete
      isOptionEqualToValue={(option, value) => {
        // Initially label is empty and it is not matching with any of options.
        // This prevents warning on the console.
        if (!value.label) {
          return true;
        }
        return option.value === value.value;
      }}
      onInputChange={(_, value, reason) => {
        if (reason === 'input') {
          onInputChange?.(value);
        } else if (reason === 'clear') {
          onClear?.();
        }
      }}
      getOptionLabel={(option) => option.label}
      options={options}
      value={value}
      onChange={(_, value) => {
        onChange(value || undefined);
      }}
      filterOptions={filterOptions}
      ListboxComponent={ListboxComponent}
      ListboxProps={{
        onScroll: (event: React.SyntheticEvent) => {
          const listboxNode = event.currentTarget;
          if (
            listboxNode.scrollTop + listboxNode.clientHeight ===
            listboxNode.scrollHeight
          ) {
            prevScrollTop.current = listboxNode.scrollTop;
            onScrollEndReached?.();
          }
        },
      }}
      renderOption={(props, option) => {
        return (
          <Box component="li" {...props} key={option.value} sx={{ height: 44 }}>
            <Typography noWrap>{option.label}</Typography>
          </Box>
        );
      }}
      popupIcon={!disabled ? <IconChevronDown size={20} /> : null}
      onBlur={onBlur}
      loading={isLoading}
      disabled={disabled}
      renderInput={(params) => (
        <ESTextField
          {...params}
          label={label}
          required={required}
          onBlur={onBlur}
          error={error}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};
