import { environment } from '@energy-stacks/feature-config';
import { createBaseQuery } from '@energy-stacks/shared';
import { createApi } from '@reduxjs/toolkit/query/react';
import { ObelisUserModel } from './obelisUserModel';
import { obelisUserModelNormalizer } from './normalizers/obelisUserModelNormalizer';
import { EditUserFormData } from './userFormData';
import { toUserRoleDto } from './normalizers/userRoleNormalizer';
import { UpdateUserRequest } from './updateUserRequest';
import { ObelisUserAccessRequestModel } from './obelisUserAccessRequestModel';
import { ObelisUserAccessRequestDto } from './obelisUserAccessRequestDto';
import { obelisUserAccessRequestNormalizer } from './normalizers/obelisUserAccessRequestNormalizer';
import { ExtendedObelisUserModel } from './extendedUserModel';
import { extendedObelisUserModelNormalizer } from './normalizers/extendedUserModelNormalizer';
import { ExtendedObelisUserDto } from './extendedObelisUserDto';

export const usersObelisApi = createApi({
  reducerPath: 'usersObelisApi',
  tagTypes: ['Users', 'UserAccessRequests', 'Avatar'],
  baseQuery: createBaseQuery(`${environment.ocppServiceUrl}/users`),
  endpoints: (builder) => ({
    getUser: builder.query<ObelisUserModel, void>({
      query: () => ({
        url: '/me',
      }),
      transformResponse: obelisUserModelNormalizer,
    }),
    getUsers: builder.query<ExtendedObelisUserModel[], void>({
      query: () => '/',
      keepUnusedDataFor: 0,
      providesTags: ['Users'],
      transformResponse: (users: ExtendedObelisUserDto[]) => {
        return users.map(extendedObelisUserModelNormalizer);
      },
    }),
    editUser: builder.mutation<ExtendedObelisUserModel, EditUserFormData>({
      query: (data) => {
        const requestBody: UpdateUserRequest = {
          userRole: toUserRoleDto(data.userRole),
          isActive: data.isActive,
        };
        return {
          url: `/${data.userId}`,
          method: 'PUT',
          body: requestBody,
        };
      },
      invalidatesTags: ['Users'],
      transformResponse: extendedObelisUserModelNormalizer,
    }),
    getUserRequests: builder.query<ObelisUserAccessRequestModel[], void>({
      query: () => '/requests',
      providesTags: ['UserAccessRequests'],
      transformResponse: (userRequests: ObelisUserAccessRequestDto[]) =>
        userRequests.map(obelisUserAccessRequestNormalizer),
    }),
    editUserRequest: builder.mutation<
      ExtendedObelisUserModel,
      EditUserFormData
    >({
      query: (data) => {
        const requestBody: UpdateUserRequest = {
          userRole: toUserRoleDto(data.userRole),
          isActive: data.isActive,
        };
        return {
          url: `/${data.userId}`,
          method: 'PUT',
          body: requestBody,
        };
      },
      transformResponse: extendedObelisUserModelNormalizer,
    }),
    deleteUser: builder.mutation<void, string>({
      query: (userId) => {
        return {
          url: `/${userId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Users'],
    }),
    deleteUserRequest: builder.mutation<void, { userId: string }>({
      query: (data) => {
        return {
          url: `/requests/${data.userId}`,
          method: 'DELETE',
        };
      },
    }),
    requestAccess: builder.mutation<void, void>({
      query: () => ({
        url: '/me/access',
        method: 'POST',
      }),
    }),
    refetchUsersAndRequests: builder.mutation<null, void>({
      queryFn: () => ({ data: null }),
      invalidatesTags: ['UserAccessRequests', 'Users'],
    }),
  }),
});

export const {
  useGetUserQuery,
  useDeleteUserMutation,
  useGetUsersQuery,
  useGetUserRequestsQuery,
  useDeleteUserRequestMutation,
  useEditUserMutation,
  useEditUserRequestMutation,
  useRefetchUsersAndRequestsMutation,
  useRequestAccessMutation,
} = usersObelisApi;
