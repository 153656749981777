import { ChargingSiteDto, GeoLocationDto } from './../chargingStationDto';
import { CentralSystemStatus } from '@energy-stacks/broker/feature-system-preferences-data';
import { ShadowChargingStationStatus } from '../brokerChargingStationShadow';
import { ChargingStationDetailsModel } from '../chargingStationDetailsModel';
import { ChargingStationDto } from '../chargingStationDto';
import { ChargingStationOnlineStatus } from '../chargingStationModel';
import { OcppProtocol } from '@energy-stacks/shared';
import { connectorShadowNormalizer } from './connectorShadowNormalizer';
import { BrokerConnectorShadow } from '../brokerConnectorShadow';
import { getCSStatus } from '../getCSStatus';

export interface BrokerChargingStationShadowDto {
  ocppProtocol: OcppProtocol;
  chargingStationSerialNumber: string;
  chargingStationVendor: string;
  chargingStationVendorModel: string;
  firmwareVersion: string;
  iccid: string;
  imsi: string;
  meterSerialNumber: string;
  meterType: string;
  chargingStationStatus: ShadowChargingStationStatus;
  ocppErrorCode: string;
  ocppErrorInfo: string;
  vendorId: string;
  vendorErrorCode: string;
  dateLastStatus: string;
  dateLastHeartBeat: string;
  dateLastBoot: string;
  connectors: BrokerConnectorShadow[];
  dateLastWebsocketPing?: string;
}

// Delivered by the stomp via broker
export interface BrokerChargingStationDto {
  id: number;
  identityKey: string;
  chargingStationName: string;
  chargingStationModel: string;
  dateCreated: string;
  online: boolean;
  csmsConnected: boolean;
  csmsUuid: string;
  shadow: BrokerChargingStationShadowDto;
  coordinates: GeoLocationDto;
  location: ChargingSiteDto;
}

export const chargingStationBrokerDetailsNormalizer = (
  response: BrokerChargingStationDto
): ChargingStationDetailsModel => {
  return {
    geolocation: response.coordinates,
    location: response.location ?? { name: 'N/A', uuid: '' },
    identityKey: response.identityKey ?? 'N/A',
    model: response.chargingStationModel ?? 'N/A',
    name: response.chargingStationName ?? 'N/A',
    csmsUuid: response.csmsUuid ?? 'N/A',
    online: response.online
      ? ChargingStationOnlineStatus.online
      : ChargingStationOnlineStatus.offline,
    csmsOnline: response.csmsConnected
      ? CentralSystemStatus.Online
      : CentralSystemStatus.Offline,
    // Display name is set in the useChargingStationDetails. Not available here
    csmsDisplayName: '',
    ...chargingStationBrokerShadowNormalizer(response),
  };
};

export const chargingStationBrokerShadowNormalizer = (response: {
  shadow: BrokerChargingStationShadowDto;
}) => {
  return {
    serialNumber: response.shadow?.chargingStationSerialNumber ?? 'N/A',
    firmware: response.shadow?.firmwareVersion ?? 'N/A',
    connectors:
      response.shadow?.connectors?.map((connector) =>
        connectorShadowNormalizer(connector)
      ) ?? [],
    firmwareVersion: response.shadow?.firmwareVersion ?? 'N/A',
    vendor: response.shadow?.chargingStationVendor ?? 'N/A',
    vendorModel: response.shadow?.chargingStationVendorModel ?? 'N/A',
    dateLastStatus: response.shadow?.dateLastStatus || '',
    iccid: response.shadow?.iccid ?? 'N/A',
    imsi: response.shadow?.imsi ?? 'N/A',
    meterSerialNumber: response.shadow?.meterSerialNumber ?? 'N/A',
    meterType: response.shadow?.meterType ?? 'N/A',
    ocppProtocol: response.shadow?.ocppProtocol ?? 'N/A',
    csStatus: getCSStatus(response.shadow?.chargingStationStatus),
    dateLastHeartBeat: response.shadow?.dateLastHeartBeat || '',
    dateLastBoot: response.shadow?.dateLastBoot || '',
    dateLastWebsocketPing: response.shadow?.dateLastWebsocketPing || '',
  };
};

export const chargingStationDetailsNormalizer = (
  response: Partial<ChargingStationDto>
): ChargingStationDetailsModel => {
  return {
    geolocation: response.coordinates
      ? {
          latitude: response.coordinates.latitude,
          longitude: response.coordinates.longitude,
        }
      : null,
    location: response.location ?? { name: 'N/A', uuid: '' },
    identityKey: response.identityKey ?? 'N/A',
    online: response.online
      ? ChargingStationOnlineStatus.online
      : ChargingStationOnlineStatus.offline,
    model: response.chargingStationModel ?? 'N/A',
    name: response.chargingStationName ?? 'N/A',
    csmsUuid: response.csmsUuid ?? 'N/A',
    csmsOnline: response.csmsConnected
      ? CentralSystemStatus.Online
      : CentralSystemStatus.Offline,
    // Display name is set in the useChargingStationDetails. Not available here
    csmsDisplayName: '',
    ...chargingStationShadowNormalizer(response),
  };
};

export const chargingStationShadowNormalizer = (
  response: Partial<ChargingStationDto>
) => {
  return {
    connectors:
      response.shadow?.connectors?.map((connector) =>
        connectorShadowNormalizer(connector)
      ) ?? [],
    serialNumber: response.shadow?.chargingStationSerialNumber ?? 'N/A',
    firmware: response.shadow?.firmwareVersion ?? 'N/A',
    firmwareVersion: response.shadow?.firmwareVersion ?? 'N/A',
    vendor: response.shadow?.chargingStationVendor ?? 'N/A',
    vendorModel: response.shadow?.chargingStationVendorModel ?? 'N/A',
    dateLastStatus: response.shadow?.dateLastStatus || '',
    iccid: response.shadow?.iccid ?? 'N/A',
    imsi: response.shadow?.imsi ?? 'N/A',
    meterSerialNumber: response.shadow?.meterSerialNumber ?? 'N/A',
    meterType: response.shadow?.meterType ?? 'N/A',
    ocppProtocol: response.shadow?.ocppProtocol ?? 'N/A',
    csStatus: getCSStatus(response.shadow?.chargingStationStatus),
    dateLastHeartBeat: response.shadow?.dateLastHeartBeat || '',
    dateLastBoot: response.shadow?.dateLastBoot || '',
    dateLastWebsocketPing: response.shadow?.dateLastWebsocketPing || '',
  };
};
