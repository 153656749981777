import { ESAutocomplete, useESSnackbar } from '@energy-stacks/core/ui';
import {
  ChargingHubModel,
  useGetChargingHubsQuery,
} from '@energy-stacks/obelis/feature-charging-hubs-data';
import { debounce, uniqBy } from 'lodash-es';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type AddChargingStationChargingHubSelectProps = {
  onError: () => void;
};

const defaultParams = {
  pageNumber: 0,
  pageSize: 10,
  searchValue: undefined,
};

export const AddChargingStationChargingHubSelect: FC<
  AddChargingStationChargingHubSelectProps
> = ({ onError }) => {
  const [t] = useTranslation('chargingStations');
  const { showSnackbar } = useESSnackbar();
  const [options, setOptions] = useState<ChargingHubModel[]>([]);
  const [chargingHubsParams, setChargingHubsParams] = useState<{
    pageNumber: number;
    pageSize: number;
    searchValue: string | undefined;
  }>(defaultParams);

  const {
    data: chargingHubsPaged,
    isLoading,
    isError,
    isFetching,
  } = useGetChargingHubsQuery(chargingHubsParams);

  const {
    formState: { errors },
    control,
  } = useFormContext();

  useEffect(() => {
    const hubs = chargingHubsPaged?.chargingHubs;
    if (hubs) {
      setOptions((prev) => {
        return uniqBy([...prev, ...hubs], (hub) => hub.id);
      });
    }
  }, [chargingHubsPaged?.chargingHubs]);

  useEffect(() => {
    if (isError) {
      onError();
      showSnackbar('error', 'fetchingChargingHubsFailed', 'chargingStations');
    }
  }, [isError, onError, showSnackbar]);

  const handleInputChange = useCallback((value: string) => {
    setOptions([]);
    setChargingHubsParams((prev) => ({
      ...prev,
      pageNumber: 0,
      searchValue: value,
    }));
  }, []);

  const debouncedHandleInputChange = useMemo(
    () => debounce(handleInputChange, 500),
    [handleInputChange]
  );

  const handleScrollEndReached = useCallback(() => {
    if (!isLoading && !isFetching && !chargingHubsPaged?.last) {
      setChargingHubsParams((prev) => {
        return {
          ...prev,
          pageNumber: prev.pageNumber + 1,
        };
      });
    }
  }, [chargingHubsPaged?.last, isFetching, isLoading]);

  return (
    <Controller
      control={control}
      rules={{ required: true }}
      render={({ field: { value, onChange, onBlur } }) => (
        <ESAutocomplete
          value={{
            value: value?.value ?? '',
            label: value?.label ?? '',
          }}
          options={options.map((chargingHub) => ({
            label: chargingHub.name,
            value: chargingHub.id,
          }))}
          onClear={() => {
            setChargingHubsParams(defaultParams);
          }}
          filterOptions={(x) => x}
          onInputChange={debouncedHandleInputChange}
          isLoading={isFetching}
          label={t('chargingStationGeneralDetailsLabels.chargingHub')}
          required
          error={Boolean(errors['chargingHub'])}
          helperText={
            Boolean(errors['chargingHub']) &&
            t(
              `chargingStationGeneralDetailsErrors.${errors['chargingHub']?.value?.message}`
            )
          }
          onChange={onChange}
          onBlur={onBlur}
          onScrollEndReached={handleScrollEndReached}
        />
      )}
      name="chargingHub"
    />
  );
};
