import { secondsToMinutes } from 'date-fns';
import { RoutePoint } from '../types/routePoint';
import { RoutePointDto } from '../types/routePointDto';

const DEFAULT_PROCESS_TIME_IN_MINUTES = 10;

export const routePointNormalizer = (
  routePoint: RoutePointDto | undefined
): RoutePoint | null => {
  if (!routePoint) {
    return null;
  }

  return {
    id: routePoint.accountLocationUid,
    street: routePoint.street || '---',
    city: routePoint.city || '---',
    geoLocation: routePoint.geoLocation
      ? {
          latitude: routePoint.geoLocation.latitude || '---',
          longitude: routePoint.geoLocation.longitude || '---',
        }
      : {
          latitude: '---',
          longitude: '---',
        },
    type: routePoint.locationType,
    risk: routePoint.locationRisk,
    products: routePoint.products,
    processTimeInMinutes:
      typeof routePoint.processTime === 'number'
        ? secondsToMinutes(routePoint.processTime)
        : DEFAULT_PROCESS_TIME_IN_MINUTES,
  };
};
