import { Divider } from '@mui/material';
import { DateRange, StartOfWeek } from './date-range';
import { getDefaultDateRange } from './getDefaultDateRange';
import { DateShortcutButtons } from './DateShortcutButtons';
import { DateRangePickerCalendar } from './DateRangePickerCalendar';
import { DatePickerWrapper } from './DatePickerWrapper';
import { useDateRange } from './useDateRange';
import { useEffect, useRef } from 'react';
import { isNil } from 'lodash-es';

interface CoreDateRangePickerProps {
  defaultDateRange: DateRange | undefined;
  dateRange?: DateRange | undefined;
  onDateRangeApplied: (dateRange: DateRange) => void;
  onDateRangeCleared: () => void;
  weekStartsOn?: StartOfWeek;
  selectablePassive?: boolean;
}

export const DateRangePicker: React.FC<CoreDateRangePickerProps> = ({
  defaultDateRange,
  dateRange,
  onDateRangeApplied,
  onDateRangeCleared,
  weekStartsOn,
  selectablePassive,
}) => {
  const defaultDateRangeRef = useRef(defaultDateRange);
  const {
    dateRange: range,
    setDateRange,
    isInvalid,
  } = useDateRange(defaultDateRangeRef.current);

  useEffect(() => {
    setDateRange(dateRange);
  }, [dateRange, setDateRange]);

  return (
    <DatePickerWrapper
      onApplyFilter={() => {
        onDateRangeApplied(range);
      }}
      onClearFilter={() => {
        onDateRangeCleared();
        setDateRange(getDefaultDateRange());
      }}
      isDateInvalid={isInvalid}
    >
      <DateRangePickerCalendar
        dateRange={range}
        onDateRangeChange={setDateRange}
        weekStartsOn={weekStartsOn}
        selectablePassive={selectablePassive}
      />
      <Divider />
      {(isNil(dateRange?.showShortcuts) || dateRange?.showShortcuts) && (
        <DateShortcutButtons onDateRangeChange={setDateRange} />
      )}
      <Divider />
    </DatePickerWrapper>
  );
};
