import { ESTooltip, formatDateInTimezone } from '@energy-stacks/shared';
import { Typography } from '@mui/material';
import { differenceInMinutes } from 'date-fns';
import { useTranslation } from 'react-i18next';

type ConfirmedTimeProps = {
  confirmedTime: string;
  plannedTime: string;
  timeZoneId?: string;
};

export const ConfirmedTime: React.FC<ConfirmedTimeProps> = ({
  confirmedTime,
  plannedTime,
  timeZoneId,
}) => {
  const [t] = useTranslation('jobs');
  return (
    <ESTooltip
      title={
        Math.abs(
          differenceInMinutes(new Date(confirmedTime), new Date(plannedTime))
        ) > 15
          ? t('delay')
          : t('onTime')
      }
    >
      <Typography
        color={
          Math.abs(
            differenceInMinutes(new Date(plannedTime), new Date(confirmedTime))
          ) <= 15
            ? 'success.dark'
            : 'error.dark'
        }
      >
        {formatDateInTimezone(confirmedTime, 'HH:mm', timeZoneId)}
      </Typography>
    </ESTooltip>
  );
};
