import {
  ESTable,
  ESTableBody,
  ESTableHead,
  ESTablePagination,
  ESTableWrapper,
  useESTableBasic,
} from '@energy-stacks/core/ui';
import {
  ExtendedObelisUserModel,
  UserRoleEnum,
} from '@energy-stacks/obelis/feature-users-data';
import {
  ClearFiltersButton,
  Email,
  NoTableData,
  TableSearchField,
  stringEnumToArray,
  TableColumnFilter,
  UserStatusChip,
} from '@energy-stacks/shared';
import { Stack } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { UserCell } from '@energy-stacks/obelis/shared';
import { ActionsUserCell } from './ActionsUserCell';
import { useNavigate } from 'react-router-dom';

interface UsersTableProps {
  users: ExtendedObelisUserModel[];
  testId?: string;
}
export const UsersTable: React.FC<UsersTableProps> = ({ users, testId }) => {
  const { t } = useTranslation('users');
  const [tShared] = useTranslation('shared');
  const navigate = useNavigate();

  const columnHelper = createColumnHelper<ExtendedObelisUserModel>();

  const columns = [
    columnHelper.accessor('displayName', {
      header: () => t('name'),
      footer: (props) => props.column.id,
      cell: (info) => (
        <UserCell
          username={info.getValue()}
          userId={info.row.original.userId}
          userEmail={info.row.original.emailAddress}
        />
      ),
    }),
    columnHelper.accessor('emailAddress', {
      header: () => t('email'),
      footer: (props) => props.column.id,
      cell: (info) => <Email mail={info.getValue()} />,
    }),
    columnHelper.accessor('userRole', {
      header: () => t('userRole'),
      footer: (props) => props.column.id,
      cell: (info) => t(`${info.getValue()}`),
      filterFn: 'arrIncludesSome',
      enableGlobalFilter: false,
    }),
    columnHelper.accessor('isActive', {
      header: () => t('userStatus'),
      footer: (props) => props.column.id,
      cell: (info) => <UserStatusChip isUserActive={info.getValue()} />,
      enableGlobalFilter: false,
    }),
    columnHelper.display({
      id: 'actions',
      header: () => tShared('actions'),
      cell: (info) => {
        const handleNavigateEdit = () => {
          navigate(`${info.row.original.userId}/edit`);
        };
        const handleNavigateDelete = () => {
          navigate(`${info.row.original.userId}/delete`);
        };
        return (
          <ActionsUserCell
            emailAddress={info.row.original?.emailAddress ?? ''}
            onEdit={handleNavigateEdit}
            onDelete={handleNavigateDelete}
          />
        );
      },
    }),
  ];

  const {
    instance,
    globalFilter,
    rows,
    rowsPerPageOptions,
    onGlobalFilterChange,
  } = useESTableBasic(users, columns, { fitRowHeight: 73 });
  const hasRows = rows.length !== 0;

  return (
    <>
      <Stack direction="row" gap={3} mb={8} alignItems="center">
        <TableSearchField
          testId={testId}
          value={globalFilter}
          onChange={onGlobalFilterChange}
          tableInstance={instance}
        />
        <TableColumnFilter
          testId={testId}
          column={instance.getColumn('userRole')}
          columnLabel={t('userRole')}
          options={stringEnumToArray(UserRoleEnum).map((v) => ({
            value: v,
            label: t(v),
          }))}
        />
        <ClearFiltersButton tableInstance={instance} />
      </Stack>
      <ESTableWrapper>
        <ESTable>
          <ESTableHead testId={testId} instance={instance} />
          <ESTableBody testId={testId} rows={rows} />
        </ESTable>

        {!hasRows ? <NoTableData message={t('thereAreNoUsers')} /> : null}

        <ESTablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          instance={instance}
        />
      </ESTableWrapper>
    </>
  );
};
