import {
  ObelisUserAccessRequestModel,
  useDeleteUserRequestMutation,
  useEditUserRequestMutation,
  useRefetchUsersAndRequestsMutation,
  UserRoleEnum,
} from '@energy-stacks/obelis/feature-users-data';
import {
  ESDialogActionButton,
  ESDialogActions,
  ESDialogContent,
  useESSnackbar,
} from '@energy-stacks/core/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { List, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { ObelisUserManageAccessItem } from './ObelisUserManageAccessItem';

export type ObelisUserAccessRequestFormEntry = ObelisUserAccessRequestModel & {
  isAccepted: boolean | null;
  role: UserRoleEnum | undefined;
};

export interface UserAccessRequestFormData {
  userAccessRequests: ObelisUserAccessRequestFormEntry[];
}

interface ObelisUserManageAccessDialogFormProps {
  userAccessRequestModels: ObelisUserAccessRequestModel[];
  onClose: () => void;
  closeWithoutConfirm: () => void;
  setIsFormDirty: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ObelisUserManageAccessDialogForm: React.FC<
  ObelisUserManageAccessDialogFormProps
> = ({
  userAccessRequestModels,
  onClose,
  closeWithoutConfirm,
  setIsFormDirty,
}) => {
  const { t } = useTranslation('users');
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
  } = useForm<UserAccessRequestFormData>({
    resolver: yupResolver(userAccessRequestValidationSchema),
    mode: 'onChange',
    defaultValues: {
      userAccessRequests: toUserAccessRequestFormEntry(userAccessRequestModels),
    },
  });

  useEffect(() => {
    setIsFormDirty(isDirty);
  }, [isDirty, setIsFormDirty]);

  useEffect(() => {
    reset({
      userAccessRequests: toUserAccessRequestFormEntry(userAccessRequestModels),
    });
  }, [reset, userAccessRequestModels]);

  const { fields, update } = useFieldArray({
    name: 'userAccessRequests',
    control,
  });

  const [editUserRequest] = useEditUserRequestMutation();
  const [deleteUserRequest] = useDeleteUserRequestMutation();
  const [refetchUsersAndRequests] = useRefetchUsersAndRequestsMutation();

  const { showSnackbar } = useESSnackbar();

  const onSubmit: SubmitHandler<UserAccessRequestFormData> = async (data) => {
    const requests = data.userAccessRequests.map((userRequest) => {
      const { role, isAccepted, userTokenId } = userRequest;

      if (isAccepted === true && role)
        return new Promise((resolve, reject) =>
          editUserRequest({
            userId: userTokenId,
            isActive: isAccepted,
            userRole: role,
          })
            .unwrap()
            .then((result) => resolve(result))
            .catch((error) => reject(error))
        );

      if (isAccepted === false)
        return new Promise((resolve, reject) =>
          deleteUserRequest({ userId: userTokenId })
            .unwrap()
            .then((result) => resolve(result))
            .catch((error) => reject(error))
        );

      return undefined;
    });

    setIsLoading(true);

    Promise.all(requests)
      .then(() =>
        showSnackbar('success', 'userRolesUpdatedSuccessfully', 'users')
      )
      .catch(() =>
        showSnackbar('warning', 'someUsersWerentGrantedAccess', 'users')
      )
      .finally(() => {
        setIsLoading(false);
        closeWithoutConfirm();
        refetchUsersAndRequests();
      });
  };

  return (
    <Stack
      component={'form'}
      onSubmit={handleSubmit(onSubmit)}
      overflow={'auto'}
    >
      <ESDialogContent>
        <List>
          {fields.map((item, index) => {
            const isLast = index === fields.length - 1;

            return (
              <ObelisUserManageAccessItem
                key={item.userId}
                requestData={item}
                index={index}
                control={control}
                update={update}
                isLast={isLast}
              />
            );
          })}
        </List>
      </ESDialogContent>
      <ESDialogActions>
        <ESDialogActionButton
          color="error"
          onClick={onClose}
          disabled={isLoading}
        >
          {t('cancel')}
        </ESDialogActionButton>
        <ESDialogActionButton
          type="submit"
          variant="contained"
          disabled={!isValid}
          loading={isLoading}
        >
          {t('done')}
        </ESDialogActionButton>
      </ESDialogActions>
    </Stack>
  );
};

function toUserAccessRequestFormEntry(
  userAccessRequestModels: ObelisUserAccessRequestModel[]
): ObelisUserAccessRequestFormEntry[] {
  return userAccessRequestModels.map((request) => ({
    ...request,
    isAccepted: null,
    role: undefined,
  }));
}

const userAccessRequestValidationSchema = yup.object().shape({
  userAccessRequests: yup
    .array()
    .of(
      yup.object().shape({
        isAccepted: yup.boolean().notRequired(),
        role: yup.string().when('isAccepted', {
          is: (isAccepted: boolean) => isAccepted === true,
          then: yup.string().required('chooseAValidRole'),
        }),
      })
    )
    .compact(
      (request: ObelisUserAccessRequestFormEntry) => request.isAccepted === null
    )
    .min(1),
});
