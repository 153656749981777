import { environment } from '@energy-stacks/feature-config';
import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { ESRoutes } from './ESRoutes';

enum StatusCode {
  FORBIDDEN = 403,
  NOT_AUTHENTICATED = 401,
}

const getAuthorization = async () => {
  const workerRegistrations =
    await navigator?.serviceWorker?.getRegistrations();

  const isOidcServiceWorkerRegistered = workerRegistrations
    ? workerRegistrations.find((registration) =>
        registration.active?.scriptURL.includes('OidcServiceWorker.js')
      )
    : undefined;

  if (!isOidcServiceWorkerRegistered) {
    const authLocalStorage = JSON.parse(
      localStorage.getItem('oidc.default') as string
    );

    if (authLocalStorage) {
      return `Bearer ${authLocalStorage.tokens?.idToken}`;
    }

    return undefined;
  }

  // Let service worker handle authorization
  return undefined;
};

export const createBaseQuery =
  (
    baseUrl: string
  ): BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> =>
  async (args, api, extraOptions) => {
    const authorization = await getAuthorization();

    const result = await fetchBaseQuery({
      baseUrl,
    })(
      {
        url: typeof args === 'string' ? args : args.url,
        headers: {
          Authorization: authorization,
          ...(typeof args === 'object' && args.headers),
        },
        ...(typeof args === 'object' && args),
      },
      api,
      extraOptions
    );

    if (result.error && typeof result.error.status === 'number') {
      // handle not authenticated or forbidden status codes
      if ([StatusCode.NOT_AUTHENTICATED].includes(result.error.status)) {
        /**
         * React Router v6 does not expose a ref to the navigation object as previous versions did. This is a push towards
         * calling navigation actions only from React components.
         *
         * For this reason, we will simply set the login state to "unauthorized/access denied" and let components/hooks respond
         * to it and navigate to Access Denied page accordingly.
         */
        // store.dispatch(setAccessDeniedForUser());
        // TODO: Resolve circular dependency issue with store, until then, navigate via window.location object
        if (window.location.pathname !== ESRoutes.AccessDenied) {
          //NOTE: Global side effect like is not compatible with Storybook
          if (!environment.storybook) {
            window.location.replace(ESRoutes.AccessDenied);
          }
        }
      }

      // handle other status codes here
    }

    return result;
  };
