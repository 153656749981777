import { Chip, colors } from '@mui/material';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

interface UserStatusChipProps {
  isUserActive: boolean;
}

export const UserStatusChip: React.FC<UserStatusChipProps> = memo(
  ({ isUserActive }) => {
    const { t } = useTranslation('users');

    return isUserActive ? (
      <Chip
        label={t('userIsActive')}
        sx={{
          backgroundColor: colors.green['A100'],
          color: colors.green[900],
        }}
      />
    ) : (
      <Chip label={t('userIsInactive')} disabled />
    );
  }
);
