import { MoreOptionsMenu } from '@energy-stacks/shared';
import { useIsLoggedInUser } from './useIsLoggedInUser';
import { ESMenuItem } from '@energy-stacks/core/ui';
import { useTranslation } from 'react-i18next';
import { ObelisFeatureRoleGuard } from '@energy-stacks/obelis/feature-user-access';

interface ActionsUserCellProps {
  emailAddress: string;
  onEdit: () => void;
  onDelete: () => void;
}

export const ActionsUserCell: React.FC<ActionsUserCellProps> = ({
  emailAddress,
  onEdit,
  onDelete,
}) => {
  const isLoggedInUser = useIsLoggedInUser(emailAddress);
  const { t } = useTranslation('users');

  return (
    <MoreOptionsMenu disableMenu={isLoggedInUser}>
      <ESMenuItem onClick={onEdit}>{t('edit')}</ESMenuItem>
      <ObelisFeatureRoleGuard feature="userDeleting">
        <ESMenuItem onClick={onDelete}>{t('deleteMenuItem')}</ESMenuItem>
      </ObelisFeatureRoleGuard>
    </MoreOptionsMenu>
  );
};
