import { TokenRenewMode } from '@axa-fr/react-oidc';
import { environment } from '@energy-stacks/feature-config';

export const oidcConfiguration = {
  client_id: environment.auth.client_id,
  redirect_uri: `${window.location.origin}/authentication/callback`,
  silent_redirect_uri: `${window.location.origin}/authentication/silent-callback`, // Optional activate silent-signin that use cookies between OIDC server and client javascript to restore the session
  scope: environment.auth.scope,
  authority: environment.auth.authority,
  service_worker_relative_url: '/OidcServiceWorker.js',
  // NOTE: Patch which we are using most likely breaks this setting -> specifically requesting userInfo data without authorization header. Fix for this should come from library. Revert patch if you want this setting to false
  service_worker_only: true,
  userinfo_endpoint: environment.auth.userInfoEndpoint,
  token_renew_mode: TokenRenewMode.id_token_invalid,
  demonstrating_proof_of_possession: false,
  storage: localStorage,
};
