import { ESTooltip, formatDateInTimezone } from '@energy-stacks/shared';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

type ForecastedTimeProps = {
  forecastedTime: string;
  timeZoneId?: string;
};

export const ForecastedTime: React.FC<ForecastedTimeProps> = ({
  forecastedTime,
  timeZoneId,
}) => {
  const [t] = useTranslation('jobs');
  return (
    <ESTooltip title={t('estimated')}>
      <Typography color="warning.dark">
        {formatDateInTimezone(forecastedTime, 'HH:mm', timeZoneId)}
      </Typography>
    </ESTooltip>
  );
};
