import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@energy-stacks/store';
import { AccessDeniedMessage } from './AccessDeniedMessage';
import { Box } from '@mui/material';
import { ESRoutes, NotFound } from '@energy-stacks/shared';

interface AccessDeniedPageProps {
  userActive?: boolean;
  userHasRoleAssigned?: boolean;
  shouldRequestAccess?: () => void;
  requestAccessIsLoading?: boolean;
  LoadingComponent: React.FC;
}

export const AccessDeniedPage: React.FC<AccessDeniedPageProps> = ({
  userActive = false,
  userHasRoleAssigned = false,
  shouldRequestAccess,
  requestAccessIsLoading,
  LoadingComponent,
}) => {
  const [t] = useTranslation('userAccess');
  const loginState = useAppSelector((state) => state.user.loginState);

  let message = t('notAuthorized');

  if (!userActive) {
    message = t('notActivated');
  }

  if (!userHasRoleAssigned) {
    message = t('roleNotAssigned');
  }

  if (
    loginState !== 'accessDenied' &&
    loginState !== 'unauthenticated' &&
    loginState !== 'loggedIn'
  ) {
    return <LoadingComponent />;
  }

  if (userActive && userHasRoleAssigned) {
    return <NotFound navigateLink={ESRoutes.Root} />;
  }

  return (
    <Box height="100vh">
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
        minHeight={600}
      >
        <AccessDeniedMessage
          message={message}
          shouldRequestAccess={shouldRequestAccess}
          requestAccessIsLoading={requestAccessIsLoading}
        />
      </Box>
    </Box>
  );
};
