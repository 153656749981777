import React from 'react';
import { ESButton } from '@energy-stacks/core/ui';
import { useTranslation } from 'react-i18next';

interface AcceptButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const AcceptButton: React.FC<AcceptButtonProps> = ({ onClick }) => {
  const { t } = useTranslation('users');

  return (
    <ESButton variant="outlined" size="small" onClick={onClick}>
      {t('accept')}
    </ESButton>
  );
};
