import { CentralSystemStatus } from '@energy-stacks/broker/feature-system-preferences-data';
import { ChargingStationDto } from '../chargingStationDto';
import {
  ChargingStationModel,
  ChargingStationOnlineStatus,
} from '../chargingStationModel';
import { appendZToDateString } from '@energy-stacks/shared';
import { connectorShadowNormalizer } from './connectorShadowNormalizer';
import {
  BrokerChargingStationDto,
  BrokerChargingStationShadowDto,
} from './chargingStationDetailsNormalizer';
import { getCSStatus } from '../getCSStatus';

export const chargingStationsBrokerNormalizer = (
  chargingStation: BrokerChargingStationDto
): ChargingStationModel => {
  return {
    geolocation: chargingStation.coordinates ?? {
      latitude: '-',
      longitude: '-',
    },
    location: chargingStation.location ?? { uuid: 'N/A', name: 'N/A' },
    identityKey: chargingStation.identityKey ?? 'N/A',
    online:
      chargingStation.online === null
        ? ChargingStationOnlineStatus.provisioned
        : chargingStation.online
        ? ChargingStationOnlineStatus.online
        : ChargingStationOnlineStatus.offline,
    model: chargingStation.chargingStationModel ?? 'N/A',
    name: chargingStation.chargingStationName ?? 'N/A',
    csmsUuid: chargingStation.csmsUuid ?? 'N/A',
    centralSystemStatus: chargingStation.csmsConnected
      ? CentralSystemStatus.Online
      : CentralSystemStatus.Offline,
    firmware: chargingStation.shadow?.firmwareVersion ?? 'N/A',
    serialNumber: chargingStation.shadow?.chargingStationSerialNumber
      ? chargingStation.shadow?.chargingStationSerialNumber
      : 'N/A',
    dateCreated: appendZToDateString(chargingStation.dateCreated),
    ocppProtocol: chargingStation.shadow?.ocppProtocol ?? 'N/A',
    connectors:
      chargingStation.shadow?.connectors?.map((connector) =>
        connectorShadowNormalizer(connector)
      ) ?? [],
    csStatus: getCSStatus(chargingStation.shadow?.chargingStationStatus),
  };
};

export const chargingStationsBrokerShadowNormalizer = (
  chargingStation: { shadow: BrokerChargingStationShadowDto },
  identityKey: string
): Partial<ChargingStationModel> => {
  return {
    identityKey,
    firmware: chargingStation.shadow?.firmwareVersion ?? 'N/A',
    serialNumber: chargingStation.shadow?.chargingStationSerialNumber
      ? chargingStation.shadow?.chargingStationSerialNumber
      : 'N/A',
    ocppProtocol: chargingStation.shadow?.ocppProtocol ?? 'N/A',
    connectors:
      chargingStation.shadow?.connectors?.map((connector) =>
        connectorShadowNormalizer(connector)
      ) ?? [],
    csStatus: getCSStatus(chargingStation.shadow?.chargingStationStatus),
  };
};

export const chargingStationsNormalizer = (
  chargingStations: Partial<ChargingStationDto>[]
): ChargingStationModel[] => {
  return chargingStations.map((chargingStation) => ({
    firmware: chargingStation.shadow?.firmwareVersion ?? 'N/A',
    // There is no geolocation field in BE
    geolocation: chargingStation.coordinates ?? {
      latitude: '-',
      longitude: '-',
    },
    location: chargingStation.location ?? { uuid: 'N/A', name: 'N/A' },
    identityKey: chargingStation.identityKey ?? 'N/A',
    model: chargingStation.chargingStationModel ?? 'N/A',
    name: chargingStation.chargingStationName ?? 'N/A',
    serialNumber: chargingStation.shadow?.chargingStationSerialNumber
      ? chargingStation.shadow?.chargingStationSerialNumber
      : 'N/A',
    online:
      chargingStation.online === null
        ? ChargingStationOnlineStatus.provisioned
        : chargingStation.online
        ? ChargingStationOnlineStatus.online
        : ChargingStationOnlineStatus.offline,
    csmsUuid: chargingStation.csmsUuid ?? 'N/A',
    centralSystemStatus: chargingStation.csmsConnected
      ? CentralSystemStatus.Online
      : CentralSystemStatus.Offline,
    dateCreated: appendZToDateString(chargingStation.dateCreated),
    ocppProtocol: chargingStation.shadow?.ocppProtocol ?? 'N/A',
    connectors:
      chargingStation.shadow?.connectors?.map((connector) =>
        connectorShadowNormalizer(connector)
      ) ?? [],
    csStatus: getCSStatus(chargingStation.shadow?.chargingStationStatus),
  }));
};

export const chargingStationsShadowNormalizer = (
  chargingStations: Partial<ChargingStationDto>[]
): Partial<ChargingStationModel>[] => {
  return chargingStations.map((chargingStation) => ({
    serialNumber: chargingStation.shadow?.chargingStationSerialNumber
      ? chargingStation.shadow?.chargingStationSerialNumber
      : 'N/A',
    ocppProtocol: chargingStation.shadow?.ocppProtocol ?? 'N/A',
    connectors:
      chargingStation.shadow?.connectors?.map((connector) =>
        connectorShadowNormalizer(connector)
      ) ?? [],
    csStatus: getCSStatus(chargingStation.shadow?.chargingStationStatus),
  }));
};
