import { useESSnackbar } from '@energy-stacks/core/ui';
import { useObelisFeatureGuard } from '@energy-stacks/obelis/feature-user-access';
import { useGetUserRequestsQuery } from '@energy-stacks/obelis/feature-users-data';
import { UserAccessRequestBanner } from '@energy-stacks/shared';
import { useEffect } from 'react';
import { ObelisRoutes } from '@energy-stacks/obelis/shared';

export const ObelisUserAccessNotificationsBanner: React.FC = () => {
  const { showSnackbar } = useESSnackbar();

  const canManageAccessRequests = useObelisFeatureGuard(
    'accessRequestManagement'
  );
  const {
    data: userRequests,
    error: userRequestsError,
    isLoading,
  } = useGetUserRequestsQuery();

  useEffect(() => {
    if (userRequestsError) {
      showSnackbar('error', 'failedToFetchUserRequests', 'users');
    }
  }, [userRequestsError, showSnackbar]);

  if (
    isLoading ||
    !canManageAccessRequests ||
    !userRequests ||
    userRequests.length === 0
  ) {
    return null;
  }

  return (
    <UserAccessRequestBanner
      requestCount={userRequests.length}
      navigateLink={ObelisRoutes.UserAccessRequests}
    />
  );
};
