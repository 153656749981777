import { FleetProduct } from '../types/product';
import { FleetProductDto } from '../types/productDto';

export const productNormalizer = (
  product: FleetProductDto | undefined | null
): FleetProduct | null => {
  if (!product) {
    return null;
  }

  return {
    id: product.uid,
    name: productNameMap[product.name] ?? product.name,
    quantity: product.quantity,
    risk: product.risk,
  };
};

export const productNameMap: Record<
  FleetProductDto['name'],
  FleetProduct['name']
> = {
  'Degassed Manure': 'degassed',
  'Raw Manure': 'raw',
  Industrial: 'industrial',
};
